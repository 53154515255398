export const filesystemConfig = {
  filesystem: {
    fileTypes: {
      image: [".jpg", ".png", ".jpeg", ".gif"],
      xls: [".xlsx", ".xls", ".xlt", ".xla"],
      pdf: [".pdf"],
    },
    user: {
      avatar: {
        maxUploadFileSize: 1024 * 1000 * 2,
        maxUploadFileSizeLabel: "2MB",
        acceptedUploadFormats: ".png, .jpeg, .jpg",
      },
    },

    project: {
      file: {
        acceptedUploadFormats: [".png", ".jpeg", ".jpg"],
        maxUploadFileSize: 1024 * 1000 * 2,
        maxUploadFileSizeLabel: "2MB",
      },
      attachment: {
        maxUploadFileSize: 1024 * 1000 * 300,
        maxUploadFileSizeLabel: "300MB",
        acceptedUploadFormats: [],
      },
      moodBoard: {
        file: {
          maxUploadFileSize: 1024 * 1000 * 300,
          maxUploadFileSizeLabel: "300MB",
        },
      },
      design: {
        file: {
          maxUploadFileSize: 1024 * 1000 * 300,
          maxUploadFileSizeLabel: "300MB",
        },
        drawImage: {
          acceptedUploadFormats: [".png", ".jpeg", ".jpg"],
          maxUploadFileSize: 1024 * 1000 * 10,
          maxUploadFileSizeLabel: "10MB",
        },
      },
    },

    tasks: {
      attachments: {
        acceptedUploadFormats: [".png", ".jpeg", ".jpg", ".gif", ".pdf"],
        maxUploadFileSize: 1024 * 1000 * 10,
        maxUploadFileSizeLabel: "10MB",
      },
    },
  },
};
