import AppLayout from "@/layouts/AppLayout";
import AuthLayout from "@/layouts/AuthLayout";
import LandingLayout from "@/layouts/LandingLayout";
import AdminLayout from "@/layouts/AdminLayout.vue";

export const layoutConfig = {
  layout: {
    app: AppLayout,
    auth: AuthLayout,
    landing: LandingLayout,
    admin: AdminLayout,
  },
};
