export const webSocketConfig = {
  ws: {
    baseURL: process.env.VUE_APP_WSS_URL,
    methods: {
      notification: 3,
      projectUpdates: 4,
      connectToProject: 5,
      exitFromProject: 6,
      createMessage: 100,
      updateMessage: 101,
      deleteMessage: 102,
      paginationMessage: 103,
    },
  },
};
