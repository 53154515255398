<template>
  <v-dialog
    v-model="getModal('createNewTaskModal').isOpen"
    max-width="660"
    @keydown.esc="hideModal"
    @click:outside="hideModal"
    :persistent="submitLoading"
    content-class="create-new-task-modal"
    :scrollable="true"
  >
    <div class="relative pt-13 px-4 px-sm-7 pb-6 gray-0 fill-height">
      <div class="d-flex align-center justify-space-between">
        <h5 class="text-title-2 gray-100--text">
          <template v-if="modalData.type === 'create'">New Task</template>
          <template v-else-if="modalData.type === 'edit'">Edit Task</template>
        </h5>
        <UiBtn fab color="gray-0" @click="hideModal">
          <IconCancel width="16" class="gray-60--text" />
        </UiBtn>
      </div>

      <v-divider class="mt-5 mb-8" />

      <div
        v-if="!task.space_id && task.isDashboard"
        class="field-validation d-flex justify-center align-center mb-4 rounded text-captions-1 error--text text-center"
      >
        Please select a space to assign a task
      </div>

      <div
        v-if="!task.project_id"
        class="field-validation d-flex justify-center align-center mb-4 rounded text-captions-1 error--text text-center"
      >
        Please select a project to assign a task
      </div>

      <div
        v-if="task.project_id && !task.project_style_id"
        class="field-validation d-flex justify-center align-center mb-4 rounded text-captions-1 warning--text text-center"
      >
        Please select a style to assign a task
      </div>

      <div
        v-if="
          task.project_id &&
          task.project_style_id &&
          createSubTask &&
          !task.parent.id &&
          getModal('createNewTaskModal').taskList.length
        "
        class="field-validation d-flex justify-center align-center mb-4 rounded text-captions-1 warning--text text-center"
      >
        Please select a task to assign a sub task
      </div>

      <div
        class="d-flex flex-wrap justify-space-between fill-width"
        v-if="task.isDashboard"
      >
        <div class="fill-width mb-3">
          <UiAutocomplete
            v-model="task.space_id"
            :items="getSpaces"
            :item-value="'id'"
            :item-text="'title'"
            clearable
            ref="space_select"
            placeholder="Select Space"
            :class="['task-required-input', { 'disable-edit': !editEnable }]"
            @update:search-input="highlightSelection($event, getSpaces)"
            @change="changeSpace"
          >
            <template #prepend-inner>
              <v-sheet
                v-if="task.space_id"
                rounded="circle"
                :color="spaceColor"
                width="14"
                height="14"
              ></v-sheet>
              <IconSearch v-else width="13" />
            </template>
            <template #item="{ item }">
              <template v-if="typeof item !== 'object'">
                <v-list-item-content v-text="item" />
              </template>
              <template v-else>
                <v-sheet
                  rounded="circle"
                  :color="item.color"
                  width="14"
                  height="14"
                  class="ml-n1 mr-2"
                ></v-sheet>
                <v-list-item-content>
                  <v-list-item-title
                    class="text-captions-1"
                    v-html="item.htmlTitle || item.title"
                  />
                </v-list-item-content>
              </template>
            </template>
          </UiAutocomplete>
        </div>
      </div>
      <div class="d-flex flex-wrap justify-space-between fill-width">
        <div class="form-row-item mb-3">
          <UiAutocomplete
            v-model="task.project_id"
            :items="getModal('createNewTaskModal').projectList"
            :item-value="'id'"
            :item-text="'title'"
            clearable
            ref="project_select"
            placeholder="Select Project"
            :class="['task-required-input', { 'disable-edit': !editEnable }]"
            :disabled="!editEnable || (!task.space_id && task.isDashboard)"
            @update:search-input="
              highlightSelection(
                $event,
                getModal('createNewTaskModal').projectList
              )
            "
            @change="changeProject"
          >
            <template #prepend-inner>
              <IconFolder
                v-if="task.project_id"
                width="14"
                class="gray-30--text"
              />
              <IconSearch v-else width="13" />
            </template>
            <template #item="{ item }">
              <template v-if="typeof item !== 'object'">
                <v-list-item-content v-text="item" />
              </template>
              <template v-else>
                <IconFolder width="14" class="ml-n1 mr-2 gray-60--text" />
                <v-list-item-content>
                  <v-list-item-title
                    class="text-captions-1"
                    v-html="item.htmlTitle || item.title"
                  />
                </v-list-item-content>
              </template>
            </template>
          </UiAutocomplete>
        </div>

        <div class="form-row-item mb-3">
          <UiAutocomplete
            v-model="task.project_style_id"
            :items="getModal('createNewTaskModal').styleList"
            :item-value="'id'"
            :item-text="'title'"
            :disabled="
              !editEnable ||
              !task.project_id ||
              (!task.space_id && task.isDashboard)
            "
            clearable
            placeholder="Select Style"
            :class="['task-required-input', { 'disable-edit': !editEnable }]"
            @update:search-input="
              highlightSelection(
                $event,
                getModal('createNewTaskModal').styleList
              )
            "
            @change="changeStyle"
          >
            <template #prepend-inner>
              <IconFolder
                v-if="task.project_style_id"
                width="14"
                class="gray-30--text"
              />
              <IconSearch v-else width="13" />
            </template>
            <template #item="{ item }">
              <template v-if="typeof item !== 'object'">
                <v-list-item-content v-text="item" />
              </template>
              <template v-else>
                <IconFolder width="14" class="ml-n1 mr-2 gray-60--text" />
                <v-list-item-content>
                  <v-list-item-title
                    class="text-captions-1"
                    v-html="item.htmlTitle || item.title"
                  />
                </v-list-item-content>
              </template>
            </template>
          </UiAutocomplete>
        </div>
      </div>

      <div v-if="createSubTask" class="mb-3">
        <div class="mb-2 text-body text-capitalize gray-100--text">
          Parent Task
        </div>
        <UiAutocomplete
          v-model="task.parent.id"
          :items="getModal('createNewTaskModal').taskList"
          :item-value="'id'"
          :item-text="'name'"
          :disabled="!editEnable || !task.project_id || !task.project_style_id"
          clearable
          placeholder="Select Task"
          class="task-required-input"
        />
      </div>

      <ValidationObserver tag="div" ref="form" v-slot="{ handleSubmit }">
        <v-form ref="form" @submit.prevent="handleSubmit(createTask)">
          <ValidationProvider
            class="mb-3"
            mode="eager"
            tag="div"
            name="task name"
            vid="name"
            rules="max:255|required"
            v-slot="{ errors }"
          >
            <div class="mb-2 text-body text-capitalize gray-100--text">
              Task name
            </div>
            <div class="d-flex align-center">
              <v-text-field
                v-model="task.name"
                dense
                outlined
                hide-details
                ref="name_input"
                placeholder="Task Name"
                :disabled="
                  !editEnable ||
                  submitLoading ||
                  !task.project_style_id ||
                  (!task.space_id && task.isDashboard)
                "
                :error="!!errors.length"
              />
              <UiCheckbox
                v-model="createSubTask"
                checkbox-form="square"
                :disabled="
                  !editEnable ||
                  submitLoading ||
                  !task.project_id ||
                  !task.project_style_id ||
                  (!task.space_id && task.isDashboard)
                "
                @change="toggleSubTask"
                hide-details
                :label="
                  modalData.type === 'edit' ? 'Sub-Task' : 'Create as Sub-Task'
                "
                class="my-0 ml-4"
              />
            </div>
            <div v-if="errors.length" class="mt-1 text-captions-1 error--text">
              {{ errors[0] | capitalize }}
            </div>
          </ValidationProvider>

          <div class="task-description-wrap relative mb-2">
            <div class="mb-2 text-body text-capitalize gray-100--text">
              Description
            </div>
            <UiMentionInput
              ref="task_description"
              class="fill-width mr-2"
              :value="
                formatToHTMLMentionMessage(task.description, task.tagged_users)
              "
              :content-class="'task-description text-captions-1 rounded overflow-y-auto'"
              :menu-container-selector="'.task-description-wrap'"
              :users="getProject.team"
              :disabled="!editEnable || submitLoading"
              :menu-class="'modal-task-description-list-menu'"
              :placeholder="'Type description here...'"
              @editMessage="(val) => (task.description = val)"
            />
          </div>
          <div class="d-flex justify-end">
            <UIFilesUploader
              v-if="editEnable"
              :files-formats="
                $config.filesystem.tasks.attachments.acceptedUploadFormats
              "
              :max-file-size="
                $config.filesystem.tasks.attachments.maxUploadFileSize
              "
              :drag-and-drop="false"
              :disabled="submitLoading"
              multiple
              @onChange="addFile"
              class="d-flex accent--text text-captions-1 text-capitalize font-weight-semi-bold cursor-pointer"
            >
              <IconUpload width="14" class="mr-2" />
              Upload files
            </UIFilesUploader>
          </div>
          <div
            v-if="task.attachments.length"
            class="d-flex space-x-4 pt-2 mb-5 overflow-x-auto"
          >
            <div
              v-for="file in task.attachments"
              :key="file.id"
              class="task-attachments-item relative flex-shrink-0 rounded gray-60--text cursor-pointer"
              @click="viewFile(file)"
            >
              <UiUploadedFile
                class="d-flex justify-center align-center fill-width fill-height rounded overflow-hidden"
                :file="file"
              />

              <button
                v-if="editEnable"
                type="button"
                :disabled="submitLoading"
                class="absolute d-flex align-center justify-center"
                @click.stop="removeFile(file.id)"
              >
                <IconCancelCircle width="18" />
              </button>
            </div>
          </div>

          <div class="d-flex flex-wrap justify-space-between fill-width">
            <div class="form-row-item mb-3">
              <div class="mb-2 text-body text-capitalize gray-100--text">
                Owner
              </div>
              <div
                class="text-input-block d-flex align-center px-3 rounded text-captions-1 gray-60--text"
              >
                <UiAvatar
                  :src="
                    task.created_by.avatar ? task.created_by.avatar.url : ''
                  "
                  :text="task.created_by.first_name"
                  size="18"
                  class="mr-2"
                />
                <span
                  class="overflow-hidden text-overflow-ellipsis text-no-wrap"
                >
                  {{ task.created_by.first_name }}
                  {{ task.created_by.last_name }}
                </span>
              </div>
            </div>

            <div class="form-row-item mb-3" v-if="task.project_id">
              <div class="mb-2 text-body text-capitalize gray-100--text">
                Assignee
              </div>
              <UISelectAssignee
                v-model="task.assignee"
                :menuWidth="
                  $vuetify.breakpoint.smAndUp ? 260 : 'calc(100% - 32px)'
                "
                :assignee-list="$store.getters.getProject.team"
                :disabled="!editEnable || submitLoading"
              >
                <template v-slot:input="{ assignedUser, isOpen }">
                  <div
                    :class="[
                      'text-input-block assignee-select d-flex align-center px-3 rounded text-captions-1 gray-60--text',
                      { 'is-open--input': isOpen },
                    ]"
                  >
                    <UiAvatar
                      :src="
                        assignedUser.user.avatar
                          ? assignedUser.user.avatar.url
                          : ''
                      "
                      :text="assignedUser.user.first_name"
                      size="18"
                      class="mr-2"
                    />
                    <span
                      class="overflow-hidden text-overflow-ellipsis text-no-wrap"
                    >
                      {{ assignedUser.user.first_name }}
                      {{ assignedUser.user.last_name }}
                    </span>
                    <IconChevronDown class="ml-auto icon-transition" />
                  </div>
                </template>
              </UISelectAssignee>
            </div>

            <div class="d-flex flex-wrap justify-space-between fill-width">
              <div class="form-row-item mb-3">
                <div class="mb-2 text-body text-capitalize gray-100--text">
                  Date created
                </div>
                <div
                  class="text-input-block d-flex align-center px-3 rounded text-captions-1 gray-60--text"
                >
                  <IconCalendarSolid class="mr-2" />
                  <span>{{ formatDate(task.created_at, "MMM dd, yyyy") }}</span>
                </div>
              </div>

              <div class="form-row-item mb-3">
                <div class="mb-2 text-body text-capitalize gray-100--text">
                  Due date
                </div>
                <UIDatePicker
                  v-model="task.due_date"
                  :disabled-date="disabledBeforeToday"
                  :disabled="!editEnable || submitLoading"
                  :append-to-body="false"
                  @save="
                    (data) => {
                      task.due_date = data.value;
                    }
                  "
                >
                  <template v-slot:input>
                    <div
                      class="text-input-block d-flex align-center px-3 rounded text-captions-1 gray-60--text"
                    >
                      <IconCalendarSolid class="mr-2" />
                      <span>{{
                        formatDate(task.due_date, "MMM dd, yyyy")
                      }}</span>
                      <IconChevronDown class="ml-auto icon-transition" />
                    </div>
                  </template>
                </UIDatePicker>
              </div>
            </div>

            <div class="form-row-item d-flex justify-space-between mb-3">
              <ValidationProvider
                class="form-row-item"
                tag="div"
                rules="required"
                name="priority"
                vid="priority"
                v-slot="{ errors }"
              >
                <div class="mb-2 text-body text-capitalize gray-100--text">
                  Priority
                </div>
                <UiPrioritySelect
                  v-model="task.priority"
                  :items="priorities"
                  :text-label="$config.tasks.priorityLabels[task.priority]"
                  append-icon=""
                  dense
                  hide-details
                  :disabled="!editEnable || submitLoading"
                  :error="!!errors.length"
                  class="text-input-block d-flex align-center px-3 rounded text-captions-1 gray-60--text"
                >
                  <template #append>
                    <IconChevronDown class="ml-auto icon-transition" />
                  </template>
                </UiPrioritySelect>
                <div
                  v-if="errors.length"
                  class="mt-1 text-captions-1 error--text"
                >
                  {{ errors[0] | capitalize }}
                </div>
              </ValidationProvider>

              <ValidationProvider
                class="form-row-item"
                tag="div"
                rules="required"
                name="status"
                vid="status"
                v-slot="{ errors }"
              >
                <div class="mb-2 text-body text-capitalize gray-100--text">
                  Status
                </div>
                <UiTaskStatusSelect
                  v-model="task.status"
                  :items="statuses"
                  :text-label="$config.tasks.statusLabels[task.status]"
                  append-icon=""
                  dense
                  hide-details
                  @change="updateTaskStatus"
                  :disabled="submitLoading"
                  :error="!!errors.length"
                  class="text-input-block d-flex align-center px-3 rounded text-captions-1 gray-60--text"
                >
                  <template #append>
                    <IconChevronDown class="ml-auto icon-transition" />
                  </template>
                </UiTaskStatusSelect>
                <div
                  v-if="errors.length"
                  class="mt-1 text-captions-1 error--text"
                >
                  {{ errors[0] | capitalize }}
                </div>
              </ValidationProvider>
            </div>

            <div class="form-row-item d-flex justify-space-between mb-3">
              <div class="form-row-item">
                <div class="mb-2 text-body text-capitalize gray-100--text">
                  Reminder
                </div>
                <UIDatePicker
                  v-model="task.reminder"
                  :disabled-date="disabledBeforeDueDate"
                  :disabled="disabledNotify || !editEnable || submitLoading"
                  :append-to-body="false"
                >
                  <template v-slot:header="{ date }">
                    <div class="ui-due-datepicker-header font-weight-semi-bold">
                      <IconClockSolid
                        class="mr-2"
                        style="width: 20px; height: 20px"
                      />
                      Reminder
                      <span class="ml-auto font-weight-semi-bold">{{
                        formatDate(date, "MMM dd, yyyy")
                      }}</span>
                    </div>
                  </template>
                  <template v-slot:input>
                    <div
                      class="text-input-block d-flex align-center px-3 rounded text-captions-1 gray-60--text"
                    >
                      <IconClockSolid class="mr-2" />
                      <span>{{ formatDate(task.reminder, "MM/dd/yyyy") }}</span>
                      <IconChevronDown class="ml-auto icon-transition" />
                    </div>
                  </template>
                </UIDatePicker>
              </div>
              <div class="form-row-item">
                <div class="mb-2 text-body text-capitalize gray-100--text">
                  Time
                </div>
                <UIDatePicker
                  v-model="task.reminder"
                  :time-picker-options="{
                    start: '00:00',
                    step: '00:30',
                    end: '23:30',
                  }"
                  format="hh:mm a"
                  type="time"
                  placeholder="hh:mm a"
                  hide-header
                  hide-footer
                  popup-class="task-reminder-timepicker"
                  :disabled="disabledNotify || !editEnable || submitLoading"
                  :append-to-body="false"
                >
                  <template v-slot:input>
                    <div
                      class="text-input-block d-flex align-center px-3 rounded text-captions-1 gray-60--text"
                    >
                      <IconSandClock class="mr-2" />
                      <span>{{ formatDate(task.reminder, "hh:mm aaa") }}</span>
                      <IconChevronDown class="ml-auto icon-transition" />
                    </div>
                  </template>
                </UIDatePicker>
              </div>
            </div>
          </div>

          <v-divider class="mt-3 mb-2" />

          <div
            v-if="editEnable"
            class="d-flex flex-wrap justify-center justify-sm-end space-x-3"
          >
            <UiBtn
              outlined
              width="146"
              color="gray-60"
              class="mt-2"
              :disabled="submitLoading"
              @click="hideModal"
            >
              Cancel
            </UiBtn>
            <UiBtn
              type="submit"
              width="182"
              color="accent"
              :disabled="
                !task.project_id ||
                (!task.space_id && task.isDashboard) ||
                !task.project_style_id ||
                (createSubTask && !task.parent.id)
              "
              :loading="submitLoading"
              class="mt-2"
            >
              {{ modalData.type === "create" ? "Create" : "Update" }}
            </UiBtn>
          </div>
        </v-form>
      </ValidationObserver>

      <div
        v-if="dataLoading"
        class="d-flex justify-center align-center absolute inset-0"
        style="background-color: rgba(255, 255, 255, 0.5)"
      >
        <v-progress-circular :size="100" color="accent" indeterminate />
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { format, isSameDay } from "date-fns";
import {
  formatToHTMLMentionMessage,
  getFileExtension,
  getFileName,
  getFileThumbnails,
  highlightText,
} from "@/utils/helpers";
import UiBtn from "@/components/UI/UiBtn";
import UiCheckbox from "@/components/UI/UiCheckbox";
import UIFilesUploader from "@/components/UI/UIFilesUploader";
import UiAvatar from "@/components/UI/UiAvatar";
import UISelectAssignee from "@/components/UI/UISelectAssignee";
import UIDatePicker from "@/components/UI/UIDatePicker";
import UiPrioritySelect from "@/components/UI/UiPrioritySelect";
import UiTaskStatusSelect from "@/components/UI/UiTaskStatusSelect";
import UiAutocomplete from "@/components/UI/UiAutocomplete";
import UiMentionInput from "@/components/UI/UiMentionInput";
import UiUploadedFile from "@/components/UI/UiUploadedFile.vue";

export default {
  name: "CreateNewTaskModal",
  components: {
    UiUploadedFile,
    IconCancel: () => import("@/components/icons/IconCancel"),
    IconCancelCircle: () => import("@/components/icons/IconCancelCircle"),
    IconUpload: () => import("@/components/icons/IconUpload"),
    IconChevronDown: () => import("@/components/icons/IconChevronDown"),
    IconClockSolid: () => import("@/components/icons/IconClockSolid"),
    IconSandClock: () => import("@/components/icons/IconSandClock"),
    IconCalendarSolid: () => import("@/components/icons/IconCalendarSolid"),
    IconSearch: () => import("@/components/icons/IconSearch"),
    IconFolder: () => import("@/components/icons/IconFolder"),

    UiBtn,
    UiCheckbox,
    UIFilesUploader,
    UiAvatar,
    UISelectAssignee,
    UIDatePicker,
    UiPrioritySelect,
    UiTaskStatusSelect,
    UiAutocomplete,
    UiMentionInput,
  },
  data() {
    return {
      dataLoading: true,
      submitLoading: false,
      priorities: Object.values(this.$config.tasks.priority),
      statuses: Object.values(this.$config.tasks.status),
      createSubTask: false,
      task: {
        id: null,
        name: "",
        description: "",
        priority: this.$config.tasks.priority.critical,
        status: this.$config.tasks.status.to_do,
        parent: null,
        project_id: null,
        space_id: null,
        project_tag_id: null,
        project_style_id: null,
        project_module_id: null,
        created_at: new Date().setHours(0, 0, 0, 0), // setHours need because date came with 00 time
        due_date: new Date().setHours(0, 0, 0, 0), // setHours need because date came with 00 time
        reminder: new Date().setHours(0, 0, 0, 0), // setHours need because date came with 00 time
        created_by: this.$store.getters.getUser,
        assignee: null,
        attachments: [],
        tagged_users: [],
      },
    };
  },
  computed: {
    ...mapGetters([
      "getAuthId",
      "getModal",
      "getProject",
      "getProjectSideDrawer",
      "getPermission",
      "getProjectPermissionData",
      "getActiveSpace",
      "getActiveDesignModule",
      "getSpaces",
    ]),
    modalData() {
      return this.getModal("createNewTaskModal").data;
    },
    spaceColor() {
      return this.getSpaces.find((space) => space.id === this.task.space_id)
        .color;
    },
    modalActions() {
      return this.getModal("createNewTaskModal").handlers;
    },
    editEnable() {
      const { projectOwner, projectManager } = this.$config.project.userRole;

      if (this.task.isDashboard) {
        return true
      }

      if (this.task.id === null) {
        return this.getPermission(this.getProject.id)?.["project-s-drawer"][
          "can-create-task-p-s-d"
        ];
      }

      return (
        (this.task.created_by.id === this.getAuthId ||
          this.getProjectPermissionData(this.getProject.id).roles.some(
            (role) => role === projectOwner || role === projectManager
          )) &&
        this.getPermission(this.getProject.id)?.["project-s-drawer"][
          "can-edit-task-p-s-d"
        ]
      );
    },
    disabledNotify() {
      return isSameDay(new Date(), this.task.due_date);
    },
  },
  async created() {
    this.task = {
      ...this.task,
      ...JSON.parse(JSON.stringify(this.modalData.taskData)),
    };

    if (this.modalData.type === "create" && !this.task.isDashboard) {
      await this.loadProjects();
    }

    if (this.task.project_id) {
      await this.loadStyles();
      await this.loadAssignee();
    }

    if (this.task.parent.id) {
      this.createSubTask = true;
      await this.loadTasks();
    }

    this.dataLoading = false;
  },
  mounted() {
    if (this.modalData.formFocusElements?.nameFocus) {
      this.$refs.name_input.focus();
    }
  },
  methods: {
    getFileThumbnails,
    getFileName,
    getFileExtension,
    formatToHTMLMentionMessage,
    async loadAssignee(newList) {
      const currentProject = await this.$api.project.get(this.task?.project_id);
      await this.$store.dispatch("setProject", currentProject.data);
      const preselectedUserId = newList
        ? this.$store.getters.getAuthId
        : this.task?.assignee_id;
      this.task.assignee = this.$store.getters.getProject.team.find(
        (user) => user.user_id === preselectedUserId
      ).user;

      if (this.modalData.formFocusElements?.assigneeFocus) {
        setTimeout(() => {
          document.querySelector(".assignee-select").click();
        }, 450);
      }
    },
    async loadProjects() {
      this.dataLoading = true
      try {
        const res = await this.$api.project.list({
          count: 100,
          space_id: this.getActiveSpace?.id || this.task.space_id || null,
        });
        this.getModal("createNewTaskModal").projectList = res.data || [];
        if (this.task.project_id) {
          await this.loadAssignee();
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.dataLoading = false
      }
    },
    async loadStyles() {
      this.dataLoading = true;
      try {
        const project = this.getModal("createNewTaskModal").projectList.find(
          (project) => project.id === this.task.project_id
        );
        // const designModule = project?.modules?.find(module => module.type === this.$config.project.moduleTypes.design);
        if (this.task?.module_id || this.getActiveDesignModule) {
          const tags = project?.tag.map((tag) => tag?.id);
          const styleList = await this.$api.projectModuleDesign.list(
            this.task.project_id,
            this.task?.module_id || this.getActiveDesignModule?.id,
            { tags }
          );
          this.getModal("createNewTaskModal").styleList =
            styleList.data?.reverse();
        } else {
          this.getModal("createNewTaskModal").styleList =
            project?.active_module?.styles.map((style) => style);
        }
      } catch (error) {
        console.error(error);
      }
    },
    async loadTasks() {
      this.dataLoading = true;
      try {
        const res = await this.$api.task.list(
          `projects/${this.task.project_id}/tasks`,
          {
            count: -1,
            project_tag_id: this.task.project_tag_id,
            project_style_id: this.task.project_style_id,
          }
        );

        this.getModal("createNewTaskModal").taskList = res.data.filter(
          (task) => task.id !== this.task?.id
        );
      } catch (error) {
        console.error(error);
      }
    },
    async changeProject() {
      if (this.task.project_id) {
        await this.loadStyles();
        this.dataLoading = false;
        this.task.project_style_id = null;
        await this.loadAssignee(true);
      }
    },
    async changeStyle() {
      if (this.task.project_tag_id) {
        const style = this.getModal("createNewTaskModal").styleList.find(
          (style) => style.id === this.task.project_style_id
        );
        if (style && style.tags?.[0].id !== this.task.project_tag_id) {
          this.task.project_tag_id = null;
        }
        if (this.task.project_style_id && this.createSubTask) {
          await this.loadTasks();
          this.dataLoading = false;
        }
      }
    },
    async changeSpace() {
      this.task.project_id = null;
      await this.loadProjects();
    },
    async toggleSubTask() {
      if (this.createSubTask) {
        await this.loadTasks();
        this.dataLoading = false;
      } else {
        this.task.parent.id = null;
      }
    },
    highlightSelection($event, list) {
      list?.forEach((item) => {
        item.htmlTitle = highlightText($event || "", item.title || "");
      });
    },
    formatDate(date, dateFormat) {
      return format(new Date(date), dateFormat);
    },
    disabledBeforeToday(date) {
      const today = new Date().setHours(0, 0, 0, 0); // setHours need because date came with 00 time
      return date < today;
    },
    disabledBeforeDueDate(date) {
      const today = new Date().setHours(0, 0, 0, 0);
      const dueDate = new Date(this.task.due_date).setHours(0, 0, 0, 0);
      return date < today || date >= dueDate;
    },
    async createTask() {
      if (this.task.id !== null) {
        await this.updateTask();
        this.hideModal();
        return;
      }

      if (this.task.attachments.find((file) => file.file)) {
        await this.createAttachmentFiles(
          this.task.attachments.filter((file) => file.file)
        );
      }

      const { message, users } =
        this.$refs.task_description.getFormattedMessageData();

      this.task.description = message;
      this.task.tagged_users = users.map((user) => user.id);

      try {
        this.submitLoading = true;
        const res = await this.$api.task.create(this.task.project_id, {
          ...this.task,
          parent_task_id: this.task.parent.id,
          assignee_id: this.task.assignee.id,
          due_date: format(this.task.due_date, "yyyy-MM-dd"),
          reminder: new Date(this.task.reminder),
          attachments: this.task.attachments.map((file) => file.id),
        });

        // const resTask = await this.$api.task.get(this.getProject.id, res.data);
        this.modalActions.onCreate(this.formatTaskFromBackend(res.data));
        this.$toast.open({
          message: "Task created successfully",
          type: "success",
          position: "top-right",
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.submitLoading = false;
        if (this.getModal("createNewTaskModal").isOpen) {
          this.hideModal();
        }
      }
    },
    async updateTask() {
      if (this.task.attachments.find((file) => file.file)) {
        await this.createAttachmentFiles(
          this.task.attachments.filter((file) => file.file)
        );
      }

      const { message, users } =
        this.$refs.task_description.getFormattedMessageData();

      this.task.description = message;
      this.task.tagged_users = users.map((user) => user.id);

      try {
        this.submitLoading = true;
        const res = await this.$api.task.update(
          this.getProject.id,
          this.task.id,
          {
            ...this.task,
            parent_task_id: this.task.parent.id,
            assignee_id: this.task.assignee.id,
            due_date: format(this.task.due_date, "yyyy-MM-dd"),
            reminder: new Date(this.task.reminder),
            attachments: this.task.attachments.map((file) => file.id),
          }
        );
        // const resTask = await this.$api.task.get(this.getProject.id, this.task.id);

        this.modalActions.onUpdate(this.formatTaskFromBackend(res.data));
        this.$toast.open({
          message: "Task updated successfully",
          type: "success",
          position: "top-right",
        });
        this.$eventBus.$emit("updateSideDrawerProjectTask", {
          actionType: "update",
          task: res.data,
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.submitLoading = false;
      }
    },
    async updateTaskStatus() {
      if (
        this.modalData.type === "create" ||
        (this.task.id && this.task.created_by.id === this.getAuthId)
      )
        return;
      this.dataLoading = true;

      try {
        const res = await this.$api.task.updateStatus(
          this.$route.params.project_id,
          this.task.id,
          { status: this.task.status }
        );

        this.modalActions.onUpdate(this.formatTaskFromBackend(res.data));
        this.$toast.open({
          message: "Task updated successfully",
          type: "success",
          position: "top-right",
        });

        this.$eventBus.$emit("updateSideDrawerProjectTask", {
          actionType: "update",
          task: res.data,
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.dataLoading = false;
      }
    },
    addFile(files) {
      files?.forEach((file, idx) => {
        const reader = new FileReader();

        const newFile = {
          id: new Date().getTime() + idx,
          type: file.type,
          original_name: file.name,
          url: "",
          file,
        };

        reader.onload = () => {
          newFile.url = reader.result;
          this.task.attachments.push(newFile);
        };

        reader.readAsDataURL(file);
      });
    },
    viewFile({ type, url, original_name }) {
      this.getProjectSideDrawer.isSideDrawerPermanent = true;
      this.$store.dispatch("openModal", {
        modalName: "previewFileModal",
        data: {
          type,
          url,
          original_name,
        },
        handlers: {
          onClose: () => {
            this.getProjectSideDrawer.isSideDrawerPermanent = false;
          },
        },
      });
    },
    removeFile(id) {
      if (this.submitLoading) return;
      this.task.attachments = this.task.attachments.filter(
        (file) => file.id !== id
      );
    },
    async createAttachmentFiles(files) {
      this.submitLoading = true;
      let formData = new FormData();

      files?.forEach((file) => {
        formData.append(`file[]`, file.file);
      });

      try {
        const attachments = await this.$api.task.uploadAttachments(
          this.getProject.id,
          formData
        );

        this.task.attachments = [
          ...this.task.attachments.filter((file) => !file.file),
          ...attachments.data,
        ];
      } catch (error) {
        console.error(error);
        this.submitLoading = false;
      }
    },
    formatTaskFromBackend(task) {
      return {
        ...task,
        due_date: new Date(task.due_date).getTime(),
        reminder: new Date(task.reminder).getTime(),
        submitLoading: false,
        children: task.children.map((child) => ({
          ...child,
          submitLoading: false,
          parent: { id: task.parent ? task.parent.id : null },
          due_date: new Date(child.due_date).getTime(),
          reminder: new Date(child.reminder).getTime(),
        })),
      };
    },
    hideModal() {
      this.modalActions.onClose();
      this.$store.dispatch("closeModal", "createNewTaskModal");
    },
  },
};
</script>

<style scoped lang="scss">
.create-new-task-modal {
  // overflow: initial;
  // overflow-y: auto !important;
}

.field-validation {
  height: 30px;
  border: 1px solid currentColor;
}

::v-deep .textarea textarea {
  max-height: 62px;
  overflow-y: auto;
}

.task-required-input.v-input--is-disabled:not(.disable-edit) {
  background-color: var(--v-gray-30-base);
}

.input-upload-btn {
  right: 20px;
  bottom: 12px;
}

.task-attachments-item {
  height: 54px;
  width: 54px;
  border: 1px solid var(--v-gray-30-base);
  background-color: var(--v-gray-10-base);
  button {
    top: -8px;
    right: -10px;
  }
}

.form-row-item {
  width: calc(50% - 16px);

  .form-row-item {
    width: calc(50% - 6px);
  }

  .mx-datepicker.ui-due-date {
    width: 100%;

    .text-input-block {
      cursor: pointer;
    }
  }
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .form-row-item {
    width: 100%;
  }
}

::v-deep .text-input-block {
  height: 30px;
  border: 1px solid var(--v-gray-30-base);

  span {
    padding-top: 1px;
  }

  svg {
    fill: transparent;
    &:nth-child(1) {
      width: 14px !important;
      height: 14px !important;
    }
  }

  svg:nth-child(3),
  .v-input__append-inner svg {
    width: 12px !important;
  }

  .v-select__selections {
    span {
      color: var(--v-gray-60-base);
    }
  }
}

.is-disabled--input {
  pointer-events: none;
}

.is-open--input .text-input-block,
.is-open--input.text-input-block {
  & > svg:first-child {
    color: var(--v-accent-base);
  }
  & > svg:last-child {
    transform: rotate(180deg);
  }
  border-color: var(--v-accent-base);
}

::v-deep .v-input--is-focused.text-input-block {
  border-color: var(--v-accent-base);

  .v-input__append-inner svg {
    transform: rotate(180deg);
  }
}

::v-deep .task-description {
  height: 72px;
  padding: 8px 12px;
  background-color: var(--v-gray-0-base);
  border: 1px solid var(--v-gray-30-base);
  color: var(--v-gray-60-base);

  &:empty::before {
    color: var(--v-gray-60-base);
  }

  &:focus {
    border-color: var(--v-accent-base);
  }
}

.modal-task-description-list-menu {
  top: 18px;
  left: 0;
}
</style>

<style lang="scss">
.create-new-task-modal.v-dialog {
  // overflow: initial;
  overflow-y: auto;
  .task-reminder-timepicker.mx-datepicker-main.ui-due-datepicker {
    width: 150px;

    .mx-time {
      width: 100%;
    }

    .mx-datepicker-header,
    .mx-datepicker-footer {
      display: none;
    }
  }
}
</style>
