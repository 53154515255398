const cadDesignFormats = [[".jpg", ".png", ".jpeg", ".gif", ".pdf", ".xlsx", ".xls", ".xlt", ".xla", ".mp4", ".avi", ".mpg", ".mkv", ".3dm", ".stl", ".obj", ".jcd"], [".3dm", ".stl", ".obj", ".jcd"]];

export const projectConfig = {
  project: {
    status: {
      draft: 0,
      in_progress: 1,
      in_review: 2,
      rejected: 3,
      approved: 4,
      archived: 10,
    },
    statusLabels: {
      0: "Created",
      1: "In progress",
      2: "In review",
      3: "Rejected",
      4: "Approved",
      10: "Archived",
    },
    statusColors: {
      0: "created",
      1: "in_progress",
      2: "in_review",
      3: "rejected",
      4: "approved",
      10: "archived",
    },
    modules: {
      moodBoard: "mood-board", // module slug
      sketchDesign: "sketch-design",
      tentativeCosting: "tentative-costing",
      technicalDrawing: "technical-drawing",
      cadDesign: "cad-design",
    },
    moduleStatus: {
      draft: 0,
      in_progress: 1,
      in_review: 2,
      approved: 3,
      finished: 4,
      skipped: 5,
      locked: 6,
    },
    moduleStatusLabels: {
      0: "Draft",
      1: "In Progress",
      2: "In Review",
      3: "Approved",
      4: "Finished",
      5: "Skipped",
      6: "Locked",
    },
    moduleStatusColors: {
      0: "draft",
      1: "in_progress",
      2: "in_review",
      3: "approved",
      4: "finished",
      5: "skipped",
      6: "locked",
    },
    moduleTypes: {
      moodBoard: 3,
      design: 4,
    },
    moduleIcons: [
      { name: "IconLightbulb", width: "15" },
      { name: "IconFolder", width: "15" },
      { name: "IconChat", width: "15" },
      { name: "IconDraw", width: "16" },
      { name: "IconCube", width: "17" },
      { name: "IconPenCurved", width: "17" },
      { name: "IconSpaces", width: "15" },
      { name: "IconCopy", width: "16" },
      { name: "IconTag", width: "17" },
      { name: "IconPalette", width: "17" },
      { name: "IconMoodboard", width: "17" },
      { name: "IconPaperclip", width: "17" },
      { name: "IconGlobe", width: "17" },
      { name: "IconPen", width: "16" },
      { name: "IconCheck", width: "17" },
      { name: "IconFile", width: "16" },
    ],
    modulesFileFormats: {
      // 'mood-board': [".jpg", ".png", ".jpeg", ".gif", '.mpeg'],
      // 'sketch-design': [".jpg", ".png", ".jpeg", ".gif", '.pdf'],
      // 'tentative-costing': [".jpg", ".png", ".jpeg", ".gif", '.pdf', ".xlsx", ".xls", ".xlt", ".xla"],
      // 'technical-drawing': [".jpg", ".png", ".jpeg", ".gif", '.pdf'],
      "mood-board": [],
      "sketch-design": [],
      "tentative-costing": [],
      "technical-drawing": [],
      "cad-design": cadDesignFormats.flat(1),
      // 'mood-board': ['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'audio/mpeg'],
      // 'sketch-design': ['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'application/pdf'],
      // 'tentative-costing': ['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'application/pdf', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
      // 'technical-drawing': ['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'application/pdf'],
      // 'cad-design': ['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
    },
    modulesFilesFormatGroup: {
      // 'cad-design': [['image/png', 'image/jpeg', 'image/jpg', 'image/gif'], ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'], []],
      "cad-design": cadDesignFormats,
    },
    userRole: {
      projectOwner: "p_owner",
      projectManager: "p_manager",
      designer: "designer",
      sketchDesigner: "s_design",
      merchandiser: "merchandiser",
      techDesigner: "t_design",
      cadDesigner: "c_design",
      reviewer: "reviewer",
      watcher: "watcher",
    },
    userRoleByDesignModules: {
      "p_owner": ["sketch-design", "tentative-costing", "technical-drawing", "cad-design"], // module slug
      "p_manager": ["sketch-design", "tentative-costing", "technical-drawing", "cad-design"],
      "designer": ["sketch-design", "tentative-costing", "technical-drawing", "cad-design"],
      "s_design": ["sketch-design"],
      "merchandiser": ["tentative-costing"],
      "t_design": ["technical-drawing"],
      "c_design": ["cad-design"],
      "reviewer": [],
      "watcher": [],
    },
    userRoleLabels: {
      "p_owner": "Project Owner",
      "p_manager": "Project Manager",
      "designer": "Designer",
      "s_design": "Sketch designer",
      "merchandiser": "Merchandiser",
      "t_design": "Tech designer",
      "c_design": "Cad designer",
      "reviewer": "Reviewer",
      "watcher": "Watcher",
    },
    designModes: {
      view: "view",
      task: "task",
      draw: "draw",
      hand: "hand",
    },
    drawModes: {
      freehand: "freehand",
      path: "path",
      text: "text",
      image: "image",
      handmode: "handmode",
    },
    drawModeColors: ["#E15744", "#FBA362", "#FFCE41", "#AFBA7A", "#7194C5", "#268BAB", "#59378D", "#222222"],
    gridView: {
      thumbnail: [
        {
          slug: "large",
          name: "Large",
          type: "thumbnail",
        },
        {
          slug: "medium",
          name: "Medium",
          type: "thumbnail",
        },
        {
          slug: "small",
          name: "Small",
          type: "thumbnail",
        },
      ],
      list: [
        {
          slug: "medium",
          name: "Medium",
          type: "list",
        },
        {
          slug: "small",
          name: "Small",
          type: "list",
        },
      ],
    },
    productTypes: {
      icons: {
        1: "jewelry/engagement_ring.svg",
        2: "jewelry/eternity_band.svg",
        3: "jewelry/ring.svg",
        4: "jewelry/earring.svg",
        5: "jewelry/necklace.svg",
        6: "jewelry/bracelet.svg",
        7: "jewelry/other.svg",
      },
    },
    projectModulesStatus: {
      assetPending: 1,
      needReview: 2,
      approvedAsset: 3,
      newProjects: 4,
    },
    workflowTypes: {
      main: 1,
      additional: 2,
    },
    workflowOpenTypesById: {
      1: "open_workflow_main",
      2: "open_workflow_additional",
    },
    workflowTypesSlugs: {
      main: "main",
      additional: "additional",
    },
  },
};
