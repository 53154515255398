var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-select',_vm._g(_vm._b({staticClass:"priority-select mt-0",attrs:{"menu-props":{
    contentClass: _vm.contentClass,
    offsetY: true,
    left: true,
    minWidth: _vm.minWidth,
    ..._vm.menuProps,
  }},scopedSlots:_vm._u([{key:"selection",fn:function({ item }){return [_c('div',{staticClass:"d-flex fill-width"},[_c('UIImage',{staticClass:"mr-3",attrs:{"width":"14","height":"14","name-path":`icons/ic-${_vm.$config.tasks.priorityLabels[
          item
        ].toLowerCase()}-priority.svg`}}),(_vm.textLabel)?_c('span',{staticClass:"text-overflow-ellipsis overflow-hidden text-no-wrap text-captions-1 ml-2"},[_vm._v(_vm._s(_vm.textLabel))]):_vm._e()],1)]}},{key:"item",fn:function({ item }){return [_c('div',{staticClass:"text-captions-1 d-flex align-center"},[_c('UIImage',{staticClass:"mr-3",attrs:{"width":"14","height":"14","name-path":`icons/ic-${_vm.$config.tasks.priorityLabels[
          item
        ].toLowerCase()}-priority.svg`}}),_vm._v(" "+_vm._s(_vm.$config.tasks.priorityLabels[item])+" ")],1)]}},{key:"append",fn:function(){return [_vm._t("append")]},proxy:true}],null,true)},'v-select',_vm.$attrs,false),_vm.$listeners))
}
var staticRenderFns = []

export { render, staticRenderFns }