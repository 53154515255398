export const tasksConfig = {
  tasks: {
    priority: {
      low: 1,
      medium: 2,
      high: 3,
      critical: 4,
    },
    priorityLabels: {
      1: "Low",
      2: "Medium",
      3: "High",
      4: "Critical",
    },
    status: {
      to_do: 1,
      in_progress: 2,
      in_review: 3,
      pause: 4,
      done: 5,
    },
    statusLabels: {
      1: "To do",
      2: "In progress",
      3: "In review",
      4: "Paused",
      5: "Done",
    },
    statusColors: {
      1: "to_do",
      2: "in_progress",
      3: "in_review",
      4: "paused",
      5: "done",
    },
  },
  filter_slugs: {
    space: "space",
    status: "status",
    due_date: "due_date",
    search: "search",
    assignee: "assignee",
    priority: "priority",
  },
};
