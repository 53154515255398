export const analyticsConfig = {
  filter_slugs: {
    spaces: "spaces",
    // tags: 'tags',
    projects: "projects",
    // statuses: 'statuses',
    modules: "modules",
    bottom_modules: "bottom_modules",
    date: "date",
    ast_date: "ast_date",
    bottom_date: "bottom_date",
    metrics_date: "metrics_date",
    module_average: "module_average",
  },
};
