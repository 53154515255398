import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import config from "../../../config";

const state = {
  designsLoading: false,
  noCreatedStyles: false,
  designs: [],
  activeDesignModule: null,
  activeStyle: null,
  activeFile: null,
  activeFiles: [],
  activeDesignMode: config.project.designModes.hand,
  drawModeData: {
    mode: '',
    color: '#E15744',
    brush: 10,
    fontSize: 60,
  },
  drawModeHistory: {
    loading: false,
    index: 0,
    steps: [],
    defaultStep: [],
  },
  filesCarousel: false,
  filters: {
    tags: null,
    module: null,
    statuses: [],
    search: '',
    tagsList: null,
  },
  allProjectStyles: [],
}

export default {
  state,
  getters,
  actions,
  mutations
}
