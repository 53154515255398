var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-navigation-drawer',{staticClass:"navigation-drawer-menu",class:{ 'sidebar-app-menu': _vm.$vuetify.breakpoint.mdAndDown },attrs:{"id":"appSideMenu","mini-variant":_vm.$vuetify.breakpoint.mdAndDown ? false : _vm.mini,"width":"216","app":""},model:{value:(_vm.$store.state.SideMenu.menu),callback:function ($$v) {_vm.$set(_vm.$store.state.SideMenu, "menu", $$v)},expression:"$store.state.SideMenu.menu"}},[_c('v-list-item',{staticClass:"menu-top"},[_c('v-list-item-avatar',{staticClass:"menu-avatar d-flex justify-start mr-0",class:{ 'menu-avatar-mini': _vm.mini },attrs:{"max-height":"30"}},[_c('UIImage',{staticClass:"menu-avatar-img",attrs:{"width":"30","name-path":`logo-b.svg`},on:{"click":_vm.clickAvatar}}),_c('v-btn',{staticClass:"menu-btn--mini",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.toggleMini.apply(null, arguments)}}},[_c('IconExpandRight',{staticClass:"gray-0--text",attrs:{"width":"17"}})],1)],1),_c('v-list-item-title',[_vm._v("UpSway")]),_c('v-btn',{staticClass:"menu-btn",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();_vm.$vuetify.breakpoint.mdAndDown
          ? _vm.$store.dispatch('toggleSideMenu')
          : _vm.toggleMini()}}},[_c('IconExpandLeft',{attrs:{"width":"17"}})],1)],1),_c('v-list',{staticClass:"py-0 mt-3 menu-list",class:{ mini: _vm.mini }},_vm._l((_vm.navLinks),function(link,idx){return _c('v-list-item',{key:idx,class:[
        'mb-5',
        { 'v-list-item--active': _vm.$route.name === link.routeName },
      ],attrs:{"exact-path":"","to":{ name: link.routeName, params: link.params },"ripple":_vm.$route.name !== link.routeName,"inactive":_vm.$route.name === link.routeName}},[_c('v-list-item-icon',{staticClass:"align-self-center mr-4 mt-0 mb-0"},[_c(link.icon,{tag:"component",staticClass:"gray-80--text",attrs:{"width":"14"}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"gray-100--text"},[_vm._v(" "+_vm._s(link.title)+" ")])],1)],1)}),1),_c('v-divider'),_c('v-list',{staticClass:"mt-3 menu-list menu-list-spaces",class:{ mini: _vm.mini }},[_c('v-list-item',{staticClass:"d-flex flex-wrap mb-3",class:[_vm.mini ? '' : 'px-4'],on:{"click":_vm.clickMySpaces}},[_c('v-list-item-icon',{staticClass:"align-self-center mr-4 mt-0 mb-0"},[_c('IconSpaces',{staticClass:"gray-80--text",attrs:{"width":"14"}})],1),(!_vm.mini)?[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"gray-100--text font-weight-semi-bold"},[_vm._v(" My Spaces ")])],1),_c('v-list-item-icon',{staticClass:"align-self-center ml-4 mt-0 mb-0"},[_c(_vm.expand ? 'IconChevronUp' : 'IconChevronDown',{tag:"component",staticClass:"gray-80--text",attrs:{"width":"13"}})],1)]:_vm._e()],2),_c('v-expand-transition',[(_vm.expand && !_vm.mini)?_c('div',{staticClass:"space-y-3 px-2"},[_c('v-list-item',{staticClass:"space-list-item",attrs:{"exact-path":"","to":{ name: 'Dashboard' },"ripple":_vm.getActiveSpace}},[_c('v-list-item-icon',{staticClass:"align-self-center mr-4 mt-0 mb-0"},[_c('v-sheet',{attrs:{"rounded":"circle","color":"in_progress","width":"14","height":"14"}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"gray-100--text"},[_vm._v(" My Space ")])],1)],1),_vm._l((_vm.spaces),function(space,idx){return _c('div',{key:idx},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-list-item',{staticClass:"space-list-item",class:[
                {
                  'v-list-item--active':
                    space.id === _vm.getActiveSpace?.id && !_vm.getActiveFolder,
                  'gray-0':
                    space.isOpenSettings && space.id !== _vm.getActiveSpace?.id,
                },
              ],attrs:{"to":{ name: 'Space', params: { id: space.id } },"ripple":space.id !== _vm.getActiveSpace?.id}},[_c('v-list-item-icon',{staticClass:"align-self-center mr-1 mt-0 mb-0"},[_c('v-sheet',{attrs:{"rounded":"circle","color":space.color,"width":"14","height":"14"}})],1),_c('v-list-item-action',{staticClass:"mt-0 mb-0 mr-2"},[_c('UiBtn',{directives:[{name:"show",rawName:"v-show",value:(space.folders?.length),expression:"space.folders?.length"}],staticClass:"space-list-item-icon",attrs:{"icon":"","width":"auto"},on:{"click":function($event){$event.preventDefault();return _vm.openSpace(space)}}},[_c('IconCaretDown',{attrs:{"width":"6"}})],1)],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"gray-100--text"},[_vm._v(" "+_vm._s(space.title)+" ")])],1),_c('v-list-item-action',{directives:[{name:"show",rawName:"v-show",value:(hover || space.isOpenSettings),expression:"hover || space.isOpenSettings"}],staticClass:"mt-0 mb-0"},[_c('UiMenu',{attrs:{"bottom":"","right":"","offset-y":"","nudge-bottom":2,"min-width":156,"items":_vm.getSpaceSettings(space)},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('UiBtn',_vm._g(_vm._b({staticClass:"p-0",attrs:{"height":"16","width":"16","icon":"","color":"gray-60"},on:{"click":function($event){$event.preventDefault();}}},'UiBtn',attrs,false),on),[_c('IconSettings',{attrs:{"width":"14"}})],1)]}}],null,true),model:{value:(space.isOpenSettings),callback:function ($$v) {_vm.$set(space, "isOpenSettings", $$v)},expression:"space.isOpenSettings"}})],1),(space.count_projects)?_c('UiDot',{attrs:{"color":"purple"}}):_vm._e()],1)]}}],null,true)}),_c('v-expand-transition',[(space.isOpenFolders)?_c('v-list',{staticClass:"py-0 menu-list"},_vm._l((space.folders),function(folder,idx){return _c('v-hover',{key:idx,scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-list-item',{staticClass:"pl-5 mt-1",class:[
                    {
                      'v-list-item--active':
                        folder.id === _vm.getActiveFolder?.id,
                    },
                  ],attrs:{"to":{ name: 'Folder', params: { id: folder.id } },"ripple":_vm.getActiveSpace}},[_c('v-list-item-icon',{staticClass:"align-self-center mr-2 mt-0 mb-0"},[_c('div',{staticClass:"folder-icon flex-shrink-0 d-flex"},[_c('IconFolderMini',{staticClass:"flex-shrink-0",style:({ color: space.color }),attrs:{"width":"15"}})],1)]),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"gray-100--text"},[_vm._v(" "+_vm._s(folder.title)+" ")])],1),_c('v-list-item-action',{staticClass:"mt-0 mb-0"},[(
                        !hover &&
                        !folder.isOpenSettings &&
                        folder.count_projects
                      )?_c('UiDot',{attrs:{"color":"purple"}}):_vm._e(),(hover || folder.isOpenSettings)?_c('UiMenu',{attrs:{"bottom":"","right":"","offset-y":"","nudge-bottom":2,"min-width":156,"items":_vm.getActiveFolderSettings(folder, space)},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('UiBtn',_vm._g(_vm._b({staticClass:"p-0",attrs:{"height":"16","width":"16","icon":"","color":"gray-60"},on:{"click":function($event){$event.preventDefault();}}},'UiBtn',attrs,false),on),[_c('IconSettings',{attrs:{"width":"14"}})],1)]}}],null,true),model:{value:(folder.isOpenSettings),callback:function ($$v) {_vm.$set(folder, "isOpenSettings", $$v)},expression:"folder.isOpenSettings"}}):_vm._e()],1)],1)]}}],null,true)})}),1):_vm._e()],1)],1)})],2):_vm._e()]),(!_vm.mini)?_c('v-list-item',{staticClass:"space-action mt-3"},[_c('v-list-item-content',[_c('UiBtn',{attrs:{"color":"accent","outlined":"","disabled":!_vm.getUser.email_verified,"block":"","to":{ name: 'CreateSpacePage' }}},[_c('IconPlus',{staticClass:"mr-2",attrs:{"width":"14"}}),_vm._v(" Create New Space ")],1),(!_vm.getUser.email_verified)?_c('div',{staticClass:"text-captions-2 text-center gray-60--text mt-2 mb-2"},[_vm._v(" Please verify your email to create a new space "),_c('br'),_c('button',{staticClass:"text-decoration-underline underline-offset-1",attrs:{"disabled":_vm.resending},on:{"click":_vm.resendEmail}},[_vm._v(" Resend email ")])]):_vm._e()],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }