export const dashboardConfig = {
  filter_slugs: {
    spaces: "spaces",
    tags: "tags",
    projects: "projects",
    statuses: "statuses",
    modules: "modules",
    date: "date",
  },
};
