<template>
  <v-dialog
    v-model="getModal('approveRejectModal').isOpen"
    max-width="660"
    @keydown.esc="hideModal"
    @click:outside="hideModal"
  >
    <div class="gray-0 pt-13 px-4 sm:px-7 pb-6">
      <div class="d-flex align-center justify-space-between">
        <h5 class="text-title-2 gray-100--text">Design Review</h5>
        <UiBtn fab color="gray-0" @click="hideModal">
          <IconCancel width="16" class="gray-60--text" />
        </UiBtn>
      </div>

      <v-divider class="mt-5 mb-8" />

      <v-flex v-if="loading" class="d-flex justify-center align-center py-16">
        <v-progress-circular :size="100" color="accent" indeterminate />
      </v-flex>

      <template v-else>
        <p class="mb-5 text-body gray-100--text font-weight-light">
          Designs awaiting review:
        </p>

        <div class="d-flex space-x-4 mb-5 pa-3 overflow-x-auto border">
          <div
            v-for="file in filesToReview"
            :key="file.id"
            @click="selectedFile = file"
            :class="[
              'selected-review-file relative border d-flex flex-column flex-shrink-0 cursor-pointer',
              { 'is-selected': selectedFile.id === file.id },
            ]"
          >
            <button
              v-if="filesToReview.length > 1"
              type="button"
              class="absolute d-flex align-center justify-center gray-60--text"
              @click="removeFile(file.id)"
            >
              <IconCancelCircle width="18" />
            </button>
            <div
              class="d-flex justify-center align-center flex-shrink-0 gray-30"
              style="height: 112px"
              @click="openFilePreview(file.file)"
            >
              <img
                v-if="
                  $config.filesystem.fileTypes.image.includes(
                    getFileExtension(file.file.original_name)
                  )
                "
                :src="file.file.url"
                alt=""
                class="fill-height max-width-100"
              />
              <UiFileIcon
                v-else
                width="30"
                :extension="getFileName(file.file.original_name).ext"
              />
            </div>
            <UiProjectStatus :id="file.status" block />
            <div class="d-flex flex-column px-2 pt-2 pb-3">
              <p
                class="d-flex align-center text-body font-weight-semi-bold gray-100--text mb-1"
              >
                <span
                  class="overflow-hidden text-overflow-ellipsis text-no-wrap"
                  >{{ getFileName(file.file.original_name).name }}</span
                >
                <span>.{{ getFileName(file.file.original_name).ext }}</span>
              </p>
              <span class="text-captions-2 gray-60--text"
                >Updated: {{ timeDistance(file.file.created_at) }}</span
              >
            </div>
          </div>
        </div>

        <div class="d-flex space-x-3 mb-4">
          <div class="fill-width overflow-hidden">
            <div class="mb-2 text-body font-weight-light gray-100--text">
              Changed by:
            </div>
            <div
              class="d-flex align-center px-2 border rounded"
              style="height: 30px"
            >
              <UiAvatar
                :src="getUser.avatar ? getUser.avatar.url : ''"
                :text="getUser.first_name"
                size="18"
                class="mr-2"
              />
              <span
                class="text-no-wrap text-overflow-ellipsis overflow-hidden text-captions-1 gray-60--text"
              >
                {{ getUser.first_name }} {{ getUser.last_name || "" }}
              </span>
            </div>
          </div>

          <div class="fill-width">
            <div class="mb-2 text-body font-weight-light gray-100--text">
              Status:
            </div>
            <UiSelect
              v-model="selectedFile.status"
              :items="fileStatuses"
              hide-details
              height="30"
              item-text="title"
              item-value="value"
            >
              <template #selection="{ item }">
                <UiProjectStatus
                  :id="selectedFile.status"
                  circle
                  class="ml-n1 mr-2"
                />
                <span class="text-captions-1 gray-60--text">{{
                  item.title
                }}</span>
              </template>
            </UiSelect>
          </div>
        </div>

        <ValidationProvider
          class="mb-2"
          mode="eager"
          tag="div"
          name="note"
          vid="note"
          rules="max:128"
          v-slot="{ errors }"
        >
          <div class="mb-2 text-body font-weight-light gray-100--text">
            Notes (Optional)
          </div>
          <v-text-field
            v-model="selectedFile.note"
            dense
            height="35"
            outlined
            hide-details
            placeholder="Type description here..."
            :error="!!errors.length"
          />
          <div v-if="errors.length" class="mt-1 text-captions-1 error--text">
            {{ errors[0] | capitalize }}
          </div>
        </ValidationProvider>

        <div class="d-flex flex-wrap justify-center justify-sm-end space-x-3">
          <UiBtn
            outlined
            width="146"
            color="gray-60"
            @click="hideModal"
            class="mt-5"
          >
            Cancel
          </UiBtn>
          <UiBtn width="184" color="accent" @click="reviewFiles" class="mt-5">
            Submit
          </UiBtn>
        </div>
      </template>
    </div>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { differenceInDays, format, formatDistanceToNow } from "date-fns";
import { getFileExtension, getFileName } from "@/utils/helpers";
import UiBtn from "@/components/UI/UiBtn";
import UiProjectStatus from "@/components/UI/UiProjectStatus";
import UiAvatar from "@/components/UI/UiAvatar";
import UiSelect from "@/components/UI/UiSelect";
import UiFileIcon from "@/components/UI/UiFileIcon.vue";

export default {
  name: "ApproveRejectModal",
  components: {
    UiFileIcon,
    IconCancel: () => import("@/components/icons/IconCancel"),
    IconCancelCircle: () => import("@/components/icons/IconCancelCircle"),
    UiBtn,
    UiProjectStatus,
    UiAvatar,
    UiSelect,
  },
  data() {
    return {
      loading: true,
      filesToReview: [],
      selectedFile: {
        id: null,
        note: "",
        status: this.$config.project.status.approved,
      },
    };
  },
  computed: {
    ...mapGetters([
      "getAuthId",
      "getUser",
      "getProject",
      "getModal",
      "getActiveFile",
      "getActiveFiles",
      "getActiveStyle",
      "getProjectDesigns",
      // 'getActiveDesignModule'
    ]),
    fileStatuses() {
      return Object.keys(this.$config.project.statusLabels).reduce(
        (acc, statusKey) => {
          if (
            +statusKey === this.$config.project.status.approved ||
            +statusKey === this.$config.project.status.rejected ||
            +statusKey === this.$config.project.status.in_review
          ) {
            acc.push({
              value: +statusKey,
              title: this.$config.project.statusLabels[statusKey],
            });
          }
          return acc;
        },
        []
      );
    },
    projectId() {
      return (
        this.getProject?.id ||
        this.getModal("approveRejectModal").data?.project?.id
      );
    },
    modalActions() {
      return this.getModal("approveRejectModal").handlers;
    },
  },
  async created() {
    if (this.getModal("approveRejectModal").data.files.length) {
      this.filesToReview = JSON.parse(
        JSON.stringify(this.getModal("approveRejectModal").data.files)
      );

      this.filesToReview = this.filesToReview.map((file) => {
        return {
          ...file,
          // note: file.request_reviews.find(reviewer => reviewer.user_id === this.getAuthId).note,
          note: "",
          status: this.getModal("approveRejectModal").data.status,
        };
      });

      this.selectedFile = this.filesToReview[0];
    }

    if (
      this.$route.query.openReview !== "" &&
      this.$route.query.openReview !== undefined
    ) {
      await this.loadFiles();
    }

    this.loading = false;
  },
  methods: {
    getFileExtension,
    getFileName,
    timeDistance(date) {
      const now = new Date();
      if (differenceInDays(new Date(date), now) < 0) {
        return format(new Date(date), "MM-dd-yyyy");
      } else {
        const res = formatDistanceToNow(new Date(date), { addSuffix: true });
        return res.replace("about ", "");
      }
    },
    openFilePreview({ type, url, original_name }) {
      this.$store.dispatch("openModal", {
        modalName: "previewFileModal",
        data: {
          type,
          url,
          original_name,
        },
      });
    },
    async loadFiles() {
      try {
        const res = await this.$api.project.getFilesToReview(this.projectId, {
          hash: this.$route.query.openReview,
        });
        this.filesToReview = res.data.map((file) => ({
          ...file,
          id: file.item.id,
          status: file.item.status,
          file: file.item.file,
        }));

        this.selectedFile = this.filesToReview[0];

        this.$router.$updateQueryParams({ openReview: "" });
      } catch (error) {
        console.error(error);
      }
    },
    async reviewFiles() {
      this.loading = true;
      const files = this.filesToReview.map((file) => ({
        file_id: file.id,
        note: file.note,
        status: file.status,
      }));

      try {
        const res = await this.$api.project.reviewFiles(this.projectId, {
          requests: files,
        });
        if (this.$route.name === "ProjectDesign" && res?.data?.length) {
          const designs = this.getProjectDesigns.map((design) => {
            const file = res.data.find(
              (file) =>
                design.items.length && design.items[0].id === file.item.id
            );
            if (file) {
              const items = design.items.map((item) => {
                return {
                  ...item,
                  status: file.item.status,
                  updated_at: file.item.updated_at,
                };
              });
              return {
                ...design,
                checked: false,
                items: items,
              };
            }
            return { ...design, checked: false };
          });
          this.$store.dispatch("setProjectDesigns", designs);
        }

        if (this.$route.name === "DesignViewPage") {
          const items = this.getActiveStyle.items.map((item) => {
            if (files.find((el) => el.file_id === item.id)) {
              return {
                ...item,
                status: files.find((el) => el.file_id === item.id).status,
                note: res.data?.[0]?.item?.note,
                note_created_at: res.data?.[0]?.item?.note_created_at,
                note_user_id: res.data?.[0]?.item?.note_user_id,
              };
            }
            return item;
          });
          this.$store.dispatch("setActiveStyle", {
            ...this.getActiveStyle,
            items: items,
          });
          this.$store.dispatch("setActiveFile", {
            ...this.getActiveFile,
            status: files[0].status,
            note: res.data?.[0]?.item?.note,
            note_created_at: res.data?.[0]?.item?.note_created_at,
            note_user_id: res.data?.[0]?.item?.note_user_id,
          });
        }

        const project = await this.$api.project.get(
          this.$route.params.project_id || this.projectId
        );
        await this.$store.dispatch("setProject", project.data);
        if (this.getProject?.modules?.length) {
          // On global dashboard we don't have modules in project data
          await this.$store.dispatch(
            "setDesignModules",
            this.getProject.modules
          );
        }

        this.modalActions?.onUpdate();

        //
        // if (files.some(file => file.status === this.$config.project.status.approved)) {
        //   await this.$store.dispatch('updateDesignModule', {
        //     id: this.getActiveDesignModule.id,
        //     status: this.$config.project.moduleStatus.finished,
        //   })
        // } else if (files.some(file => file.status === this.$config.project.status.rejected)) {
        //   await this.$store.dispatch('updateDesignModule', {
        //     id: this.getActiveDesignModule.id,
        //     status: this.$config.project.moduleStatus.approved,
        //   })
        // }
      } catch (error) {
        console.error(error);
      } finally {
        this.hideModal();
        this.loading = false;
      }
    },
    hideModal() {
      this.$emit("hideModal");
      this.$store.dispatch("closeModal", "approveRejectModal");
    },
    removeFile(file_id) {
      this.filesToReview = this.filesToReview.filter((file) => file.id !== file_id);
    },
  },
};
</script>

<style scoped lang="scss">
.border {
  border: 1px solid var(--v-gray-30-base);
}

.selected-review-file {
  width: 174px;
  button {
    top: -8px;
    right: -10px;
    border-radius: 50%;
    background-color: var(--v-gray-0-base);
  }
  &.is-selected {
    border-color: var(--v-accent-base);
  }
}
</style>
