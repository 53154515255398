import { render, staticRenderFns } from "./UiAutocomplete.vue?vue&type=template&id=79ebda62&scoped=true&"
import script from "./UiAutocomplete.vue?vue&type=script&lang=js&"
export * from "./UiAutocomplete.vue?vue&type=script&lang=js&"
import style0 from "./UiAutocomplete.vue?vue&type=style&index=0&id=79ebda62&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79ebda62",
  null
  
)

export default component.exports