export default (axios) => ({
  /**
   * Get last comments
   *
   * @link https://devapi2.upsway.io/apidoc/index.html#api-Dashboard-GetApiV1DashboardComments
   * @returns {*}
   */
  getComments() {
    return axios.get("/dashboard/comments");
  },

  /**
   * Get project module
   *
   * @link https://devapi2.upsway.io/apidoc/index.html // TODO: Add link
   * @param params
   * @returns {*}
   */
  getProjectModules(params = {}) {
    return axios.get("/dashboard/project-modules", { params });
  },

  /**
   * Mark project modules as viewed/unviewed
   *
   * @link https://devapi2.upsway.io/apidoc/index.html // TODO: Add link
   * @param data
   * @returns {*}
   */
  toggleModulesView(data = {}) {
    return axios.patch("/dashboard/project-modules/view", data);
  },

  /**
   * Filter Get Project Modules
   *
   * @link https://devapi2.upsway.io/apidoc/index.html // TODO: Add link
   * @param params
   * @returns {*}
   */
  getFilterModules(params = {}) {
    return axios.get("/dashboard/filter/project-modules", { params });
  },

  /**
   * Filter Get Projects
   *
   * @link https://devapi2.upsway.io/apidoc/index.html // TODO: Add link
   * @param params
   * @returns {*}
   */
  getFilterProjects(params = {}) {
    return axios.get("/dashboard/filter/projects", { params });
  },

  /**
   * Filter Get Statuses
   *
   * @link https://devapi2.upsway.io/apidoc/index.html // TODO: Add link
   * @param params
   * @returns {*}
   */
  getFilterStatuses(params = {}) {
    return axios.get("/dashboard/filter/statuses", { params });
  },
  /**
   * Filter Get Statuses
   *
   * @link https://devapi2.upsway.io/apidoc/index.html // TODO: Add link
   * @param params
   * @returns {*}
   */
  getFilterPriority(params = {}) {
    return axios.get("/dashboard/filter/priorities", { params });
  },
  /**
   * Filter Get Statuses
   *
   * @link https://devapi2.upsway.io/apidoc/index.html // TODO: Add link
   * @param params
   * @returns {*}
   */
  getFilterAssignee(params = {}) {
    return axios.get("/dashboard/assignee", { params });
  },

  /**
   * Filter Get Statuses
   *
   * @link https://devapi2.upsway.io/apidoc/index.html // TODO: Add link
   * @param params
   * @returns {*}
   */
  getFilterTags(params = {}) {
    return axios.get("/dashboard/filter/tags", { params });
  },

  /**
   * Get column settings for dashboard table
   *
   * @link https://devapi2.upsway.io/apidoc/index.html // TODO: Add link
   * @returns {*}
   */
  getColumnSettings() {
    return axios.get("/dashboard/column-settings");
  },

  /**
   * Update column settings for dashboard table
   *
   * @link https://devapi2.upsway.io/apidoc/index.html // TODO: Add link
   * data
   * @returns {*}
   */
  updateColumnSettings(data) {
    return axios.patch("/dashboard/column-settings", data);
  },

  /**
   * Get quick filter statuses
   *
   * @link https://devapi2.upsway.io/apidoc/index.html // TODO: Add link
   * data
   * @returns {*}
   */
  getQuickFilterStatuses() {
    return axios.get("/dashboard/filter/tab-statuses");
  },
});
