export default {

  /**
   *
   * @param state
   * @param payload
   * @constructor
   */
  SET_PROJECT_DESIGNS_LOADING(state, payload) {
    state.designsLoading = payload;
  },

  /**
   *
   * @param state
   * @param payload
   * @constructor
   */
  SET_PROJECT_DESIGNS(state, payload = []) {
    state.designs = [...payload.map(item => {
      if (item.checked === undefined) {
        return {
          ...item,
          newTitle: item.title,
          ttl: state.activeDesignModule.ttl,
          checked: false,
          showRenameInput: false,
          loading: false,
          fileDownloading: false,
        }
      }
      return {...item, ttl: state.activeDesignModule.ttl};
    })];
  },

  /**
   *
   * @param state
   * @param payload
   * @constructor
   */
  SET_PROJECT_DESIGNS_FILTERS(state, payload) {
    state.filters = {...state.filters, ...payload};
  },

  /**
   *
   * @param state
   * @param payload
   * @constructor
   */
  TOGGLE_FILES_CAROUSEL(state, payload) {
    state.filesCarousel = payload;
  },

  /**
   *
   * @param state
   * @param payload
   * @constructor
   */
  SET_ACTIVE_DESIGN_MODULE(state, payload) {
    state.activeDesignModule = payload;
  },

  /**
   *
   * @param state
   * @param payload
   * @constructor
   */
  SET_ACTIVE_STYLE(state, payload) {
    state.activeStyle = payload;
  },

  /**
   *
   * @param state
   * @param payload
   * @constructor
   */
  SET_ACTIVE_FILE(state, payload) {
    state.activeFile = payload;
  },

  /**
   *
   * @param state
   * @param payload
   * @constructor
   */
  SET_ACTIVE_FILES(state, payload) {
    state.activeFiles = payload;
  },

  /**
   *
   * @param state
   * @param payload
   * @constructor
   */
  SET_ALL_PROJECT_STYLES(state, payload = []) {
    state.allProjectStyles = payload;
  },

  /**
   *
   * @param state
   * @param payload
   * @constructor
   */
  UPDATE_DRAW_MODE_DATA(state, payload) {
    state.drawModeData = {...state.drawModeData, ...payload};
  },

  /**
   *
   * @param state
   * @param payload
   * @constructor
   */
  UPDATE_DRAW_MODE_HISTORY(state, payload) {
    state.drawModeHistory = {...state.drawModeHistory, ...payload};
  },

}
